html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: none;
}
::-webkit-scrollbar-thumb {
  background-color: #585858;
  border-radius: 3px;
  &:hover {
    background-color: #414141;
  }
}

.App {
  font-family: 'Lexend';
  font-weight: 200;
  margin: 0;
  padding: 0 60px;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Global */
.icon {
  max-height: 30px;
  max-width: 30px;
}
.inline-icon {
  max-height: 28px;
  max-width: 32px;
  margin-left: 4px;
  vertical-align: bottom;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  color: black;
  h1 {
    font-size: 24px;
  }
}

.nav-menu {
  ul {
    list-style-type: none;
    display: flex;
  }
  a {
    text-decoration: none;
    font-size: 16px;
    color: black;
    padding: 8px 16px;
    &:hover {
      color: white;
      background-color: #333333;
      border-radius: 30px;
      transition: background-color 0.3s ease;
    }
  }
}

/* Main section */
.main-section {
  padding: 160px 0 200px 0;
}
.main-grid {
  display: grid;
  grid-template-columns: 400px auto;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
}

.main-title {
  font-size: 32px;
  font-weight: bold;
}
.main-image {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  vertical-align: bottom;
}

.red-pin {
  display: inline;
  width: 20px;
  height: 20px;
  vertical-align: bottom;
}

.social-icon {
  border-radius: 20%;
  margin-right: 20px;
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}
.github {
  border-radius: 50%;
}

.profile-image {
  width: 180px;
  height: 240px;
  animation: profileAnimation 30s infinite;
  border: 2px solid black;
  display:  flex;
  align-items: center;
  justify-content: center;
}

@keyframes profileAnimation {
  0%,
  100% {
    border-radius: 30% 70% 70% 30% / 30% 52% 48% 70%;
    box-shadow: 0 -2vmin 4vmin white inset, 0 -4vmin 4vmin white inset,
      0 -2vmin 7vmin white inset;
  }

  10% {
    border-radius: 50% 50% 20% 80% / 25% 80% 20% 75%;
  }

  20% {
    border-radius: 67% 33% 47% 53% / 37% 20% 80% 63%;
  }

  30% {
    border-radius: 39% 61% 47% 53% / 37% 40% 60% 63%;
    box-shadow: 0 -4vmin 8vmin white inset, -1vmin -2vmin 6vmin white inset,
      -1vmin -2vmin 4vmin white inset, 1vmin 4vmin 8vmin white inset;
  }

  40% {
    border-radius: 39% 61% 82% 18% / 74% 40% 60% 26%;
  }

  50% {
    border-radius: 100%;
    box-shadow: 0 4vmin 16vmin white inset, 0 2vmin 5vmin white inset, 0 4vmin 4vmin white inset,
      0 6vmin 8vmin white inset;
  }

  60% {
    border-radius: 50% 50% 53% 47% / 72% 69% 31% 28%;
  }

  70% {
    border-radius: 50% 50% 53% 47% / 26% 22% 78% 74%;
    box-shadow: 1vmin 1vmin 8vmin white inset,
      2vmin -1vmin 4vmin white inset, -1vmin -1vmin 16vmin white inset;
  }

  80% {
    border-radius: 50% 50% 53% 47% / 26% 69% 31% 74%;
  }

  90% {
    border-radius: 20% 80% 20% 80% / 20% 80% 20% 80%;
  }
}

.tech-stack {
  padding: 100px 0 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}

.tech-skills {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  border-left: 1px solid darkgray;
  img {
    margin-left: 30px;
  }
  img:first-child {
    margin-left: 50px;
  }
}

/* Default styles */
.section-title {
  // margin: 10px 0;
  color: #2AFA74;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

/* Grid Template */
.grid-section {
  padding: 150px 0;
  display: grid;
  grid-template-columns: auto 400px;
  gap: 60px;
}

.grid-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.grid-title {
  font-size: 22px;
  font-weight: bold;
}

.grid-icon {
  height: 20px;
  vertical-align: bottom;
  margin-left: 5px;
}

.grid-desc {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  ul {
    padding-left: 20px;
    margin: 15px 0;
  }
  ul li {
    padding: 2px;
  }
}

/* About */
.about-image {
  width: 400px;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Projects */
#projects {
  margin: 300px;
  display: flex;
  flex-direction: column;
}

.puzzle-piece {
  height: 18px;
  margin-left: 4px;
}

.project {
  display: flex;
  margin: 60px 0;
  gap: 60px;
  div {
    max-width: 300px;
    font-size: 15px;
  }
}
.project-image {
  width: 500px;
  height: 300px;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
}

.project-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    font-size: 15px;
    font-weight: 600;
  }
  ul li {
    margin-right: 30px;
  }
}

.icon-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
  &:hover {
    color: #2AFA74;
  }
  img {
    margin-left: 5px;
  }
}


#contact {
}


.footer {
  margin-top: 160px;
  padding: 20px;
  display: flex;
  justify-content: center;
}